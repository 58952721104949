import { ruta } from "./fdc.conf"
import axios from 'axios'
//import { reject, resolve } from "core-js/fn/promise"

export const fdcService = {
    execute,
}

async function execute(service, request, parametros, headers) {
    console.log("Header:", headers)
    let servicio = ruta.getRuta(service)
    if (servicio === undefined)     return () => {console.log('Error: no se encontre el servicio ingresado, ', service)};
    switch (servicio.method) {
        case "get":
            return new Promise( (resolve, reject) => {
                axios.get(servicio.path + request.join("/"), { headers: {...headers}, params: {...parametros, ...ruta.dominioPortal()}}).then((response) => {
                   console.log("_URL GET:", response.request)
                   resolve(response.data);
                }).catch((error) => {
                    console.log("ERROR:", error.response)
                    console.log("Error Peticion: "+ error.response.config.url+" Request: "+request+" Detalle: "+error )
                    reject({ resultado: "error", mensaje: "No fue posible obtener respuesta", "expired": (error.response.status >= 400)});
                });
            })
            break;
        case "post":
            return new Promise((resolve, reject) => {
                console.log("Data POST", request)
                axios.post(servicio.path, request, { params: {...parametros, ...ruta.dominioPortal()}, headers: {...headers}}).then((response) => {
                    //console.log("_URL POST:", response.request.responseURL)
                    resolve(response.data);
                }).catch((error) => {
                    console.log("Error Peticion: "+servicio.path+" Request: "+request+" Detalle: "+error )
                    reject({ resultado: "error", mensaje: "No fue posible obtener respuesta", "expired": (error.response.status >= 400)});
                });
            })
            break;
        case "delete":
            return new Promise((resolve, reject) => {
                axios.delete(servicio.path + request.join("/"), {headers: {...headers}, params: {...ruta.dominioPortal()}}).then((response) => {
                    //console.log("_URL DELETE:", response.request.responseURL)
                    resolve(response.data);
                }).catch((error) => {
                    console.log("Error Peticion: "+servicio.path+" Request: "+request+" Detalle"+error )
                    reject({ resultado: "error", mensaje: "No fue posible obtener respuesta", "expired": (error.response.status >= 400)});
                });
            })
            break;
        case "put":
            return new Promise((resolve, reject) => {
                axios.put(servicio.path, request, { params: {...parametros, ...ruta.dominioPortal()}, headers: {...headers}}).then((response) => {
                    //console.log("_URL PUT:", response.request.responseURL)
                    resolve(response.data);
                }).catch((error) => {
                    console.log("Error Peticion: "+servicio.path+" Request: "+request+" Detalle"+error )
                    reject({ resultado: "error", mensaje: "No fue posible obtener respuesta", "expired": (error.response.status >= 400)});
                });
            })
            break;
    } 
}