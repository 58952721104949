<template>
<div>
    <carousel class="corrusel"  :per-page="responsive"  :navigationEnabled='false' navigationNextLabel="❯" navigationPrevLabel='❮' :paginationEnabled="true">
        <slide v-for="(item, index) in data" :key="index" >
            <div class="row">

                <b-card
                  :img-src="url + item.idnana"
                    img-alt="Image"
                    img-top
                    tag="article"
                    class="tarjeta mb-2"
                >
                
                    <b-card-title style="font-size: 18px;">{{ item.primer_nombre.replace(/(^|\s)\S/g, l => l.toUpperCase()) + ' ' + item.primer_apellido.replace(/(^|\s)\S/g, l => l.toUpperCase()) }}</b-card-title>
                    <!--<b-card-sub-title class="mb-2">Card Sub Title</b-card-sub-title>-->
                    <b-card-text class="mt-3">
                        {{ 'Edad: '+ ' ' + item.edad }}<br>
                        {{ 'Años de experiencia: '+ ' ' + item.anios_experiencia }}
                    </b-card-text>
                   <!--  <perfil  :data="item.idnana"    /> -->
                    <b-button  style="background-color: #00B290 ;" href="#" @click="abrirModal(item.idnana)" >Ver Nana</b-button>
                    
                </b-card>
                
            </div>   
        </slide>
        
    </carousel>
    <div style="display: flex; justify-content: center; align-items: center;" >
        <div class="container-center" >
          <div  style="text-align: center; margin-top: 10px;"><b-button size="lg" style="background-color: #1fa326 ;"  @click="listaTodas()" >Ver todas las Nanas</b-button></div>
        </div>
    </div>
  <div v-if="modal">
        <ModalPerfil :abrirModal='modal' :data="dataNana" :id='state' @cerrarModal="cerrarModal"  />
    </div> 
    
</div>
<!--  -->
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
/* import Perfil from '@/views/Perfil.vue'; */
import ModalPerfil from '@/components/ModalPerfil.vue';
import { fdcService } from "@/api/dispatcher.service";
export default {
    props: ["data","state"],
    components: {
        Carousel,
        Slide,
        ModalPerfil
    },
    data() {
      return {
        slide: 0,
        sliding: null,
        url: "https://admincn.fabricadecodigo.dev:8081/imagen/nana/",
        modal: false,
        dataNana: null,
        perfil: false,
        responsive : null,
        servis: '',
        idi: null
      }
    },
    created () {
        window.console.log('CONTENIDO PROPS STATE TARJERTA', this.state)
        this.datosPerfil()
        this.pantalla()
        this.formato()
    },
    methods: {
        onSlideStart(slide) {
            this.sliding = true
        },
        onSlideEnd(slide) {
            this.sliding = false
        },
        async datosPerfil(id){
            let resp = await fdcService.execute("nanaPerfil",[id],{},{});
            window.console.log("respuesta perfil nana", resp);
            if(resp.resultado == 'ok'){            
                this.dataNana = resp.nana
                this.modal = true
            }            
        },
        listaTodas(){
            window.console.log('CONTENIDO DE PROPS STATE', this.state)
            /* window.location.href = 'servicios/'+ this.state */
           this.$router.push('servicios/'+ this.state) 
        },

        abrirModal(id){   
           this.datosPerfil(id)
        },
        cerrarModal(){
            this.modal = false
        },
        pantalla(){
            if (screen.width < 1024) {
                this.responsive = 2
            }else{
                this.responsive = 5
            }
        }    

            
                 
          
    },

}
</script>

<style scoped>
.rounded-0{
  width: 310px !important;
  height: 240px !important;
  margin-right: 2px !important;
}


@media only screen and  (max-width: 768px) {
 .tarjeta{
    max-width: 12rem;
    /* background-color: rgb(216, 240, 234); */
 }
 }
 @media only screen and (min-width: 1200px) {
    .tarjeta{
    max-width: 17rem;
    /* background-color: rgb(216, 240, 234); */
 }
 

}   


</style>