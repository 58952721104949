import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: "/videos",
    name: "Videos",
    meta: {
      authRequired: true,
    },
    component: () => import('../views/Videos.vue'),
  },
  {
    path: "/servicios/:state",
    name: "Servicios",
    meta: {
      authRequired: true,
    },
    component: () => import('../views/Servicios.vue'),
  },
  {
    path: "/perfil",
    name: "Perfil",
    meta: {
      authRequired: true,
    },
    component: () => import('../views/Perfil.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
