<template>
<div>
<!-- Footer Area Start -->
    <div class="footer-area" id="contacto">
        <div class="footer-container">
            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <!-- Start single blog -->
                        <div class="col-md-6 col-lg-3 mb-md-30px mb-lm-30px">
                            <div class="">
                               
                                <img src="@/assets/images/logo/logofooter.png" id="golo"  alt="" >
                                <img src="@/assets/images/logo/logofooter2.png" id="golo2" class="mt-" alt=""> 
                                <!--<div>
                                   <img src="@/assets/images/logo/logofooter2.png" id="golo2" class="mt-" alt=""> 
                                </div>-->
                            </div>
                        </div>
                        <!-- End single blog -->
                        <!-- Start single blog -->
                        <div class=" col-md-6">
                            <div class=" mt-2">
                                <h3 class="text-center text_color" >CONTACTO</h3>
                                <b-alert v-if="alerta" variant="success" show>Formulario de contacto enviado exitosamente</b-alert>
                                <b-form class="container" >
                                    <div class="row col-md-12 ">
                                        <div class="col-md-6 mt-2 ">
                                            <b-form-group>   
                                                <b-form-input class="input" id="input-nombre" v-model="contacto.nombre" placeholder="Nombre"  type="text"></b-form-input>                                                                        
                                            </b-form-group>                       
                                        </div>               
                                        <div class="col-md-6 mt-2">
                                            <b-form-group>
                                                <b-form-input class="input" id="input-email" v-model="contacto.correo" placeholder="Correo electrónico"  type="email"></b-form-input>                                                                       
                                            </b-form-group>
                                        </div>  
                                        <div class="col-md-12 mt-2">
                                            <b-form-group>
                                                <b-form-input class="inputa" id="input-asunto" v-model="contacto.asunto" placeholder="Asunto"  type="text"></b-form-input>                                                                       
                                            </b-form-group>
                                        </div>  
                                        <div class="col-md-12 mt-2">
                                            <b-form-group>
                                                <b-form-textarea rows="5" class="input" id="input-mensaje" v-model="contacto.mensaje" placeholder="Mensaje" type="text"></b-form-textarea>                                                                       
                                            </b-form-group>
                                        </div>  

                                    </div>
                                    <div class="row mt-4">
                                        <b-button-group class="col-md-6">
                                            <b-overlay :show="show" class="d-inline-block" >
                                              <b-button   variant="info" @click="enviarMensaje()" >ENVIAR</b-button>
                                            </b-overlay>
                                            
                                        </b-button-group>
                                        
                                    </div> 
                                </b-form>
                            </div>
                        </div>
                        <!-- End single blog -->
                       
                        <!-- Start single blog -->
                        <div class=" single-wedge1 col-md-4 col-lg-3 col-sm-6">
                            <div class="s">
                                <div class="footer-links">
                                    <!-- News letter area -->
                                    <b-icon id="icon-footer" class="icon-geo-alt" icon="geo-alt"  ></b-icon>
                                    <p class="address"><b>Dirección</b> <br>
                                        Luis Thayer Ojeda 0130 of 606.
                                        Providencia Metro Tobalaba.
                                    </p>
                                    <b-icon id="icon-footer" class="icon-phone" icon="phone"  ></b-icon>
                                    <p class="phone"><b>Teléfono</b> <br>+569 9256 5857 Atención Clientes. <br>+569 9256 5857 Atención Nanas.</p>
                                    <b-icon id="icon-footer" class="icon-envelope" icon="envelope"  ></b-icon>
                                    <p class="mail"><b>E-mail</b> <br><a href="mailto:contacto@chilenanas.cl">contacto@chilenanas.cl</a></p>
                                    <!-- News letter area  End -->
                                </div>
                            </div>
                        </div>
                        <!-- End single blog -->
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-center">
                            <p class="copy-text"> © 2021 <strong>CHILENANAS.</strong> Desarrollado con<i class="fa fa-heart"
                                    aria-hidden="true"></i> para ChileNanas por <a class="company-name" href="https://fabricadecodigo.cl/">
                                    <strong> Fábrica de Codigo</strong></a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer Area End -->
</div>
</template>

<script>
import {fdcService} from "@/api/dispatcher.service";

export default {
  name: 'footer-component',

    data() {
        return {
            contacto:{
                nombre: null,
                correo: null,
                asunto: null,
                mensaje: null
            },
            alerta: false,
            show: false
        }
    },
    methods: {
       async enviarMensaje() {
           this.show = true
           let resp = await fdcService.execute("contactos", this.contacto)
           window.console.log("Respuesta", resp)
           if(resp.resultado == 'ok'){
                this.show = false
                this.alerta = true
                setTimeout(() => {
                  this.contacto = {}  
                  this.alerta = false
                }, 3000);
                
            }else{
                this.show = false
                this.$bvToast.toast(resp.mensaje, {
                    title: 'Operación fallida',
                    variant: 'danger',
                    solid: true
                })
              
            }
        }
    },







}
</script>

<style>

#golo{
    width: 280px; height:280px;
}
#golo2{
    width: 280px; height:280px;
    bottom:  20px;
    
}
#icon-footer{
  color: aquamarine;
}
#input-nombre{
    background: rgb(100, 96, 96);
}
#input-email{
    background: rgb(100, 96, 96);
}
#input-asunto{
    background: rgb(100, 96, 96);
}
#input-mensaje{
    background: rgb(100, 96, 96);
}

#input-nombre::placeholder {
    color: rgb(0, 0, 0);
}
#input-email::placeholder{
    color: rgb(0, 0, 0);
}
#input-asunto::placeholder{
    color: rgb(0, 0, 0);
}
#input-mensaje::placeholder{
    color: rgb(0, 0, 0);
}

.text_color{
    color: aliceblue;
    font-weight: 700;
    font-size: 40px; 
}
/* MEDIDAS CELULAR */
@media only screen and  (max-width: 768px) {
 .texto-carrusel{
  color:#FFFDFD;
  font-size: 30px;
  font-weight: 900;
}
.sub-text-carrusel{
  color:rgb(237, 238, 238);
  font-size: 8px;
  margin-right: 70px;
}
.caja-texto{
margin-bottom: 5%;
}

#golo{
    width: 280px; height:280px;
    margin-left: 40px;
}
#golo2{
    width: 280px; height:280px;
    bottom:  20px;
    margin-left: 45px;
    
}
.footer-links{
    margin: 20px;
}
}

</style>
