<template>
  <div>
        <!-- FFranga superior negra -->
    <!--<div class="header-to-bar"> AGENCIA DE NANAS WEB  </div>-->
    <!-- iNICIO HEADER -->
    <header>
      <div class="holito ">
        <div class="col-md-12 ml-2">
          <div class="row col-md-12">
            <div class="col-auto align-self-center ml-2">
              <div class="header-logo ml-2 ">
                <a href="/"><img id="logonana" src="@/assets/images/logo/logonanas2.png" alt="Site Logoaaa "  /></a>
              </div>
            </div>
            <div class="col align-self-center d-none d-lg-block">
              <div class="main-menu">
                <ul>
                  <li><a id="color-label" href="/">Inicio</a></li>
                  <li><a id="color-label" href="/#comofuncionamos">Como funcionamos</a></li>
                  <li class="dropdown "><a id="color-label" href="#">Nuestra Empresa <i class="pe-7s-angle-down"></i></a>
                    <ul class="sub-menu"> 
                      <li><a href="/videos">Videos</a></li>
                    </ul>
                  </li>
                  <!-- <li><a id="color-label" href="/servicios">Servicios</a></li> -->
                  <li><a id="color-label" href="#contacto">Contáctenos</a></li>
                </ul>
              </div>
            </div>
            <!-- Header Action Start -->
            <div class="col col-lg-auto align-self-center pl-0">
              <div class="header-actions">
                <a href="login.html" class="header-action-btn " id="login-btn1" data-bs-toggle="modal" data-bs-target="#loginActive">Ingresar</a>
                <!-- Single Wedge Start -->
              
                <!-- Single Wedge End -->
                <!-- Single Wedge Start -->
                
                <!-- Single Wedge End -->
                
                <a href="#offcanvas-mobile-menu" class="header-action-btn header-action-btn-menu offcanvas-toggle d-lg-none">
                    <i class="pe-7s-menu"></i>
                </a>
              </div>
              <!-- Header Action End -->
            </div>
          </div>
        </div>
      </div>
    </header>

    <div class="offcanvas-overlay">
        <div class="inner1 " :style="alturaDinamica">
            <div>
                <img id="logonana" src="@/assets/images/logo/logonanas2.png" alt="Site Logoaaa "  />
            </div>
            <div class="inner2">
                <ul class="puntoUl">
                    <li class="puntoli"><router-link class="puntoli" to="/">Inicio</router-link></li><hr class="col">
                    <li class="puntoli"><a @click="redireccion1()" >Como funcionamos </a></li><hr class="col">
                    <li class="puntoli"><router-link class="puntoli" to="/videos">Videos</router-link></li><hr class="col">
                    <!-- <li class="puntoli"><router-link class="puntoli" to="/servicios">Servicios</router-link></li><hr class="col"> -->
                    <li class="puntoli"><a @click="redireccion()" >Contáctenos </a></li>
                </ul>
            </div>
            <!-- OffCanvas Menu End -->
            
        </div>
    </div>
    <!-- OffCanvas Wishlist Start -->
    <div id="offcanvas-wishlist" class="offcanvas offcanvas-wishlist">
        <div class="inner">
            <div class="head">
                <span class="title">Wishlist</span>
                <button class="offcanvas-close">×</button>
            </div>
            <div class="body customScroll">
                <ul class="minicart-product-list">
                    <li>
                        <a href="single-product.html" class="image"><img src="@/assets/images/product-image/1.jpg"
                                alt="Cart product Image"></a>
                        <div class="content">
                            <a href="single-product.html" class="title">Women's Elizabeth Coat</a>
                            <span class="quantity-price">1 x <span class="amount">$21.86</span></span>
                            <a href="#" class="remove">×</a>
                        </div>
                    </li>
                    <li>
                        <a href="single-product.html" class="image"><img src="@/assets/images/product-image/2.jpg"
                                alt="Cart product Image"></a>
                        <div class="content">
                            <a href="single-product.html" class="title">Long sleeve knee length</a>
                            <span class="quantity-price">1 x <span class="amount">$13.28</span></span>
                            <a href="#" class="remove">×</a>
                        </div>
                    </li>
                    <li>
                        <a href="single-product.html" class="image"><img src="@/assets/images/product-image/3.jpg"
                                alt="Cart product Image"></a>
                        <div class="content">
                            <a href="single-product.html" class="title">Cool Man Wearing Leather</a>
                            <span class="quantity-price">1 x <span class="amount">$17.34</span></span>
                            <a href="#" class="remove">×</a>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="foot">
                <div class="buttons">
                    <a href="wishlist.html" class="btn btn-dark btn-hover-primary mt-30px">view wishlist</a>
                </div>
            </div>
        </div>
    </div>
    <!-- OffCanvas Wishlist End -->
    <!-- OffCanvas Cart Start -->
    <div id="offcanvas-cart" class="offcanvas offcanvas-cart">
        <div class="inner">
            <div class="head">
                <span class="title">Cart</span>
                <button class="offcanvas-close">×</button>
            </div>
            <div class="body customScroll">
                <ul class="minicart-product-list">
                    <li>
                        <a href="single-product.html" class="image"><img src="@/assets/images/product-image/1.jpg"
                                alt="Cart product Image"></a>
                        <div class="content">
                            <a href="single-product.html" class="title">Women's Elizabeth Coat</a>
                            <span class="quantity-price">1 x <span class="amount">$18.86</span></span>
                            <a href="#" class="remove">×</a>
                        </div>
                    </li>
                    <li>
                        <a href="single-product.html" class="image"><img src="@/assets/images/product-image/2.jpg"
                                alt="Cart product Image"></a>
                        <div class="content">
                            <a href="single-product.html" class="title">Long sleeve knee length</a>
                            <span class="quantity-price">1 x <span class="amount">$43.28</span></span>
                            <a href="#" class="remove">×</a>
                        </div>
                    </li>
                    <li>
                        <a href="single-product.html" class="image"><img src="@/assets/images/product-image/3.jpg"
                                alt="Cart product Image"></a>
                        <div class="content">
                            <a href="single-product.html" class="title">Cool Man Wearing Leather</a>
                            <span class="quantity-price">1 x <span class="amount">$37.34</span></span>
                            <a href="#" class="remove">×</a>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="foot">
                <div class="buttons mt-30px">
                    <a href="cart.html" class="btn btn-dark btn-hover-primary mb-30px">view cart</a>
                    <a href="checkout.html" class="btn btn-outline-dark current-btn">checkout</a>
                </div>
            </div>
        </div>
    </div>
    <!-- OffCanvas Cart End -->

    <!-- OffCanvas Menu Start -->
    <div id="offcanvas-mobile-menu" class="offcanvas offcanvas-mobile-menu">
        <button class="offcanvas-close"></button>

        <div class="inner customScroll">

            <div class="offcanvas-menu mb-4">
                <ul>
                    <li><a href="#"><span class="menu-text">Inicio</span></a>    
                    </li>
                    <li><a href="#"><span class="menu-text">Como Funcionamos</span></a>    
                    </li>
                    <li><a href="#"><span class="menu-text">Nuestra empresa</span></a>
                        <ul class="sub-menu">
                            <li><a href="blog-grid.html">Videos</a></li>
                        </ul>
                    </li>
                    <li><a href="about.html">Servicios</a></li>
                    <li><a href="contact.html">Contáctenos</a></li>
                </ul>
            </div>
            <!-- OffCanvas Menu End -->
            <div class="offcanvas-social mt-auto">
                <ul>
                    <li>
                        <a href="#"><i class="fa fa-facebook"></i></a>
                    </li>
                    <li>
                        <a href="#"><i class="fa fa-twitter"></i></a>
                    </li>
                    <li>
                        <a href="#"><i class="fa fa-google"></i></a>
                    </li>
                    <li>
                        <a href="#"><i class="fa fa-youtube"></i></a>
                    </li>
                    <li>
                        <a href="#"><i class="fa fa-instagram"></i></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- OffCanvas Menu End -->
  </div>
</template>

<script>
export default {
  name: 'header-component',
  data() {
      return {
          alturaDinamica: "height: "+ screen.height + "px"
      }
  },
  methods: {
      redireccion() {
          window.location.href = "#contacto"
      },
      redireccion1() {
          window.location.href = "/#comofuncionamos"
      }
  },
  mounted () {
        var $window = $(window),
        $body = $("body");
     /*---------------------------
       Menu Fixed On Scroll Active
    ------------------------------ */
    $(window).on("scroll", function (e) {
        var window_top = $(window).scrollTop() + 1;
        if (window_top > 250) {
            $(".sticky-nav").addClass("menu_fixed animated fadeInDown");
        } else {
            $(".sticky-nav").removeClass("menu_fixed animated fadeInDown");
        }
    });



    /*-------------------------------
        Create an toggle
    ---------------------------------*/

    $('.color a').on('click', function () {
        $('.sidebar-widget-list a').removeClass('active');
        $(this).addClass('active');
    });

    $('.size a').on('click', function () {
        $('.sidebar-widget-list a').removeClass('active-2');
        $(this).addClass('active-2');
    });


    /*-------------------------------
        Create an toggle
    ---------------------------------*/

    $('.pro-details-color a').on('click', function () {
        $('.pro-details-color a').removeClass('active-color');
        $(this).addClass('active-color');
    });

    $('.pro-details-size a').on('click', function () {
        $('.pro-details-size a').removeClass('active-size');
        $(this).addClass('active-size');
    });





    /*---------------------------------
        Off Canvas Function
    -----------------------------------*/
    (function () {
        var $offCanvasToggle = $(".offcanvas-toggle"),
            $offCanvas = $(".offcanvas"),
            $offCanvasOverlay = $(".offcanvas-overlay"),
            $mobileMenuToggle = $(".mobile-menu-toggle");
        $offCanvasToggle.on("click", function (e) {
            e.preventDefault();
            var $this = $(this),
                $target = $this.attr("href");
            $body.addClass("offcanvas-open");
            $($target).addClass("offcanvas-open");
            $offCanvasOverlay.fadeIn();
            if ($this.parent().hasClass("mobile-menu-toggle")) {
                $this.addClass("close");
            }
        });
        $(".offcanvas-close, .offcanvas-overlay").on("click", function (e) {
            e.preventDefault();
            $body.removeClass("offcanvas-open");
            $offCanvas.removeClass("offcanvas-open");
            $offCanvasOverlay.fadeOut();
            $mobileMenuToggle.find("a").removeClass("close");
        });
    })();

    /*----------------------------------
        Off Canvas Menu
    -----------------------------------*/
    function mobileOffCanvasMenu() {
        var $offCanvasNav = $(".offcanvas-menu, .overlay-menu"),
            $offCanvasNavSubMenu = $offCanvasNav.find(".sub-menu");

        /*Add Toggle Button With Off Canvas Sub Menu*/
        $offCanvasNavSubMenu.parent().prepend('<span class="menu-expand"></span>');

        /*Category Sub Menu Toggle*/
        $offCanvasNav.on("click", "li a, .menu-expand", function (e) {
            var $this = $(this);
            if ($this.attr("href") === "#" || $this.hasClass("menu-expand")) {
                e.preventDefault();
                if ($this.siblings("ul:visible").length) {
                    $this.parent("li").removeClass("active");
                    $this.siblings("ul").slideUp();
                    $this.parent("li").find("li").removeClass("active");
                    $this.parent("li").find("ul:visible").slideUp();
                } else {
                    $this.parent("li").addClass("active");
                    $this.closest("li").siblings("li").removeClass("active").find("li").removeClass("active");
                    $this.closest("li").siblings("li").find("ul:visible").slideUp();
                    $this.siblings("ul").slideDown();
                }
            }
        });
    }
    mobileOffCanvasMenu();


    /*----------------------------------
     * Offcanvas: User Panel
     ----------------------------------*/
    function mobileOffCanvasUserPanel() {
        var $offCanvasNav = $('.offcanvas-userpanel'),
            $offCanvasNavSubMenu = $offCanvasNav.find('.user-sub-menu');

        /*Add Toggle Button With Off Canvas Sub Menu*/
        $offCanvasNavSubMenu.parent().prepend('<span class="offcanvas__user-expand"></span>');

        /*Category Sub Menu Toggle*/
        $offCanvasNav.on('click', 'li a, .offcanvas__user-expand', function (e) {
            var $this = $(this);
            if ($this.attr('href') === '#' || $this.hasClass('offcanvas__user-expand')) {
                e.preventDefault();
                if ($this.siblings('ul:visible').length) {
                    $this.parent('li').removeClass('active');
                    $this.siblings('ul').slideUp();
                    $this.parent('li').find('li').removeClass('active');
                    $this.parent('li').find('ul:visible').slideUp();
                } else {
                    $this.parent('li').addClass('active');
                    $this.closest('li').siblings('li').removeClass('active').find('li').removeClass('active');
                    $this.closest('li').siblings('li').find('ul:visible').slideUp();
                    $this.siblings('ul').slideDown();
                }
            }
        });
    }
    mobileOffCanvasUserPanel();
  },
}
</script>

<style>
#logonana{
    width: 98px;
    
    margin-left: 20%;
}


.holito{
    background-color: #242424 ;
}
#color-label{
    color: rgb(250, 245, 248)
    
}
.main-menu ul li:hover > a, .main-menu ul li.active > a {
    color: #1DA08A !important;
}
#jj:hover{
   color:#1717FF
}
#login-btn1{
    color: #1DA08A;
}
#login-btn1:hover{
    color: whitesmoke;
}
#scrollUp {
   
    background-color: #1DA08A !important;
    
}
.offcanvas-close{
    background: oldlace;
}
/* MEDIDAS CELULAR */
@media only screen and  (max-width: 768px) {
    .header-actions .header-action-btn {
    position: relative;
    display: flex;
    text-decoration: none;
    color: #1DA08A !important;
    font-weight: 600;
    margin-right: 10px;  
}
.header-actions .header-action-btn i:hover {
    color: #f8f7f7;
}
.inner1 {
    background-color:rgba(22, 21, 21, 0.9);  
    width: 50%;  
}
.inner2{
    margin-left: 20px;
}
.puntoUl{
    padding: 20px;
    padding-top: 20px;
}
.puntoli{
    font-size: 20px ;
    margin-top: 15px;
    list-style-type: disc;
    color: papayawhip;
    font-weight:500;
    text-decoration:none;
}
.col{
    color: papayawhip;
}
}
/* MEDIDAS TABLET-IPAD */
@media only screen and  (min-width: 768px) {
  .header-actions .header-action-btn {
    position: relative;
    display: flex;
    text-decoration: none;
    color: #1DA08A !important;
    font-weight: 600;
    margin-right: 10px;  
}
#scrollUp {
   
    background-color: #1DA08A !important;
    
    
}
.header-actions .header-action-btn i:hover {
    color: #f8f7f7;
}
.inner1 {
    background-color:rgba(22, 21, 21, 0.9);  
    width: 50%;  
}
.inner2{
    margin-left: 20px;
}
.puntoUl{
    padding: 20px;
    padding-top: 20px;
}
.puntoli{
    font-size: 20px ;
    margin-top: 15px;
    list-style-type: disc;
    color: papayawhip;
    font-weight:500;
    text-decoration:none;
}
.col{
    color: papayawhip;
}
}
/* MEDIDAS DE ESCRITORIO */
@media only screen and (min-width: 1200px) {
    #scrollUp {
        background-color: #1DA08A !important;
    }   
}


</style>
