import {rutas} from './rutas_diccionario.conf'


export const ruta = {
    getRuta,
    getHeader,
    dominioPortal
}

function getRuta(servicio)  {
    return rutas.find(element => element.service == servicio)
}

function getHeader()    {
    const usuario = JSON.parse(localStorage.getItem("user"));
    console.log("usuario:", usuario)
    if(usuario !== null) {
        return {
            headers: { Authorization: 'Bearer '  + usuario.token }
        }
    }else{
        return false
    }

}
function dominioPortal(){
    return {}
}