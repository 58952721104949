<template>
<div>
  <div style="display: flex; justify-content: center; align-items: center;" >
    <div class="container-center" >
      <div  style="text-align: center;"><h5 ><b>{{data}}</b></h5></div>                       
      <div  style="text-align: center;" class="col">
        <b-badge class="badge1" v-for="(item, index) in data2" :key="index"  pill>{{item}}
        </b-badge>
      </div>
    </div>    
  </div> 
</div>                       
</template>

<script>
export default {
    props: ['data', 'data2', 'id'],
    
    created () {
       window.console.log('CONTENIDO PROPS DATA ATRIBUTOS',this.data) ;
    },
    methods: {
        collapse(index) {
      this.$root.$emit("bv::toggle::collapse", "collapse-" + index);
    },
    },

}
</script>

<style scoped >
.atri{
    max-width: 20rem;
    border: none !important ;
    
    
}
.item{
  margin-left: 15px;
}
.badge1 {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.99em;
    font-weight: 700;
    line-height: 1;
    color: rgb(247, 236, 236);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    background-color: #00B290;
    margin-left: 10px;
    margin-bottom: 10px;
}
</style>