<template>
  <div>
     <!-- Feature Area Srart -->
    <div class="feature-area pb-100px">
        <div class="container">
           <div class="row">
                <div class="col-12">
                    <div class="section-title text-center mb-0">
                        <h2 class="title_servis"><b>Servicios que nos diferencian</b> </h2>
                    </div>
                </div>
            </div>
            <div class="row col-md-12 mt-5">
                <div class=" col-md-3  ">
                    <!-- single item -->
                    <div class="single-feature">            
                        <div class="feature-content1 text-center">
                                <div class="feature-icon1 ">
                                    <b-icon class="icon-person" icon="person" font-scale="7" ></b-icon>
                                </div>
                            <h4 class="title1 mt-3" >Servico de autoatención</h4>
                            <span class="sub-title1">Busca a tu futura colaboradora en la selección de las
                                mejores candidateas ya certificadas, tu las llamas a ellas y sus antiguaos
                                empleadores,(recomendaciones validadas)
                            </span>
                        </div>
                    </div>
                </div>
                <!-- single item -->
                <div class="col-md-3 mb-md-30px mb-lm-30px mt-lm-30px ">
                    <div class="single-feature">
                        <div class="feature-content1 text-center">
                            <div class="feature-icon1 ">
                                <b-icon class="icon-star" icon="star" font-scale="7" ></b-icon>
                            </div>
                            <h5 class="title1 mt-3">Servicio premium</h5>
                            <span class="sub-title1">Conversamos contigo y hacemos la búsqueda por ti, coordinamos
                                 las entrevistas en nuestras oficinas,tu casa o videoconferencia. Incluye informe
                                 psicológico la elegida. 60 dias por Garantia de reemplazo</span>
                        </div>
                    </div>
                </div>
                <!-- single item -->
                <div class=" col-md-3 mb-md-30px mb-lm-30px mt-lm-30px">
                    <div class="single-feature">
                        <div class="feature-content1 text-center">
                            <div class="feature-icon1 ">
                                <img src="@/assets/images/icons/person.png" id="icon-person" alt="">
                            </div>
                            <h4 class="title1 mt-3">Servicio personalizado</h4>
                            <span class="sub-title1">Nosotros te ayudamos a buscar la mejor nana, las conocemos
                                muy bien y juntos buscamos a la nana ideal. 45 días por Garantia de reemplazo
                            </span>
                        </div>
                    </div>
                </div>
                 <!-- single item -->
                <div class="col-md-3 mb-md-30px mb-lm-30px mt-lm-30px">
                    <div class="single-feature">
                        <div class="feature-content1 text-center">
                            <div class="feature-icon1 ">
                                <b-icon class="icon-clock" icon="clock" font-scale="7" ></b-icon>
                            </div>
                            <h4 class="title1 mt-3">Servicio por hora</h4>
                            <span class="sub-title1">Proximamente tendremos servicios por hora para aseo, cuidado
                                de niños u cuidado de adulto mayor.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="feature-area pb-100px">
        <div class="container">
           <div class="row">
                <div class="col-12">
                    <div class="section-title text-center mb-0">
                        <h2 class="title_servis"><b>Servicios</b> </h2>
                    </div>
                </div>
            </div>
            <div class="row col-md-12 mt-5">
                <div class=" col-md-3 mb-md-30px mb-lm-30px mt-lm-30px">
                    <b-card style="max-width: 20rem; border: none;" class="mb-2">
                        <div style="text-align: center;" >
                             <b-button 
                                class="ml-1" v-b-tooltip.hover title="Ver Nanas" 
                                pill 
                                size="xl" 
                                :style="btncolor"
                                @click="listarTarifas(0,1)" 
                            >
                                 <b-icon icon="person" font-scale="7" ></b-icon>
                            </b-button>
                        </div>
                        <div style="text-align: center;">
                            <b-card-text class="mt-2" ><h5>Puertas Adentro</h5></b-card-text>
                        </div>
                     </b-card>
                </div>
                <!-- single item -->
                <div class=" col-md-3 mb-md-30px mb-lm-30px mt-lm-30px">
                    <b-card style="max-width: 20rem;  border: none;" class="mb-2">
                        <div style="text-align: center;" >
                             <b-button 
                                class="ml-1" v-b-tooltip.hover title="Ver Nanas" 
                                pill 
                                size="xl" 
                                :style="btncolor1"
                                @click="listarTarifas(1,2)" 
                            >
                                 <b-icon  icon="person" font-scale="7" ></b-icon>
                            </b-button>
                        </div>
                        <div style="text-align: center;">
                            <b-card-text  class="mt-2" ><h5>Puertas Afuera</h5></b-card-text>
                        </div>
                     </b-card>
                </div>
                <!-- single item -->
                <div class=" col-md-3 mb-md-30px mb-lm-30px mt-lm-30px">
                    <b-card style="max-width: 20rem;  border: none;" class="mb-2">
                        <div style="text-align: center;" >
                             <b-button 
                                class="ml-1" v-b-tooltip.hover title="Ver Nanas" 
                                pill 
                                size="xl" 
                                :style="btncolor2"
                                :pressed="state"
                                @click="listarTarifas(2,3)" 
                            >
                                 <b-icon  icon="person"  font-scale="7" ></b-icon>
                            </b-button>
                        </div>
                        <div style="text-align: center;">
                            <b-card-text  class="mt-2" ><h5>Por Días</h5></b-card-text>
                        </div>
                     </b-card>
                </div>
                 <!-- single item -->
                <div class=" col-md-3 mb-md-30px mb-lm-30px mt-lm-30px">
                    <b-card style="max-width: 20rem;  border: none;" class="mb-2">
                        <div style="text-align: center;" >
                             <b-button 
                                class="ml-1" v-b-tooltip.hover title="Ver Nanas" 
                                pill 
                                size="xl" 
                                :style="btncolor3"
                                @click="listarTarifas(3,4)" 
                            >
                                 <b-icon  icon="person" font-scale="7" ></b-icon>
                            </b-button>
                        </div>
                        <div style="text-align: center;">
                            <b-card-text class="mt-2" > <h5>Por Horas</h5></b-card-text>
                        </div>
                     </b-card>
                </div>
            </div>
        </div>
    </div>
    <!-- Feature Area End -->
    <div v-if="show" >
    <b-card no-body >
        <TarjetaNanaVue :data="nanas" :state="stado" />
    </b-card>
    </div> 
</div>
    
</template>


<script>

import TarjetaNanaVue from '@/components/TarjetaNana.vue'
import { fdcService } from "@/api/dispatcher.service";

export default {
  name: 'features-component',
  computed: {
    btncolor() {
        if(this.state) {
            return {background: '#1fa326'}
        }else{
            return {background: '#1FA38E'}
        }
    },
    btncolor1() {
        if(this.state1) {
            return {background: '#1fa326'}
        }else{
            return {background: '#1FA38E'}
        }
    },
    btncolor2() {
        if(this.state2) {
            return {background: '#1fa326'}
        }else{
            return {background: '#1FA38E'}
        }
    },
    btncolor3() {
        if(this.state3) {
            return {background: '#1fa326'}
        }else{
            return {background: '#1FA38E'}
        }
    },
  },
  
  components: {
    TarjetaNanaVue,
        
    },
    data() {
        return {
            nanas: [],
            show: false,
            state: false,
            state1: false,
            state2: false,
            state3: false,
            stado: null
        }
    },
    created () {
       
        
    },
    methods: {
        async listarTarifas(id,estado) {
            let resp = await fdcService.execute("tipoServicio",[id],{},{});
            window.console.log("respuesta de listar nanas", resp);
            this.nanas = []
            if(resp.resultado == 'ok'){
                this.nanas = resp.data
                this.show = true
                if(estado == 1){this.state = true, this.stado = 0}else{this.state = false}
                if(estado == 2){this.state1 = true, this.stado = 1}else{this.state1 = false}
                if(estado == 3){this.state2 = true, this.stado = 2}else{this.state2 = false}
                if(estado == 4){this.state3 = true, this.stado = 3}else{this.state3 = false}               
                window.console.log("Contenido de listar nanas", this.nanas);
            }else{
                this.$bvToast.toast('Falló, reitente o contacte a soporte', {
                    title: 'Operación fallida',
                    variant: 'danger',
                    solid: true
                })
            }        
        },
    },
}
</script>

<style>
#icon-person{
    background: linear-gradient(
		-155deg,
		rgb(30, 163, 141) 0%,
		rgb(30, 163, 141) 98%,
		rgb(243, 223, 224) 100%
	);
    border-radius: 100%;
  width: 150px; height: 150px;
}
.icon-person{
    color: white;
}
.icon-star{
    color: white;
}
.icon-clock{
    color: white;
}
.title_servis{
    font-size: 400%;
    min-width: 600;

}
.title1 {
	font-size: 20px;
	color: #272727;
	font-weight: 600;
    margin: 0 0 3px;
	
}
.sub-title1 {
	font-size: 13px;
	color: #848484;
	font-weight: 400;
    line-height: 0.5;
	
}
.feature-content {
	width: calc(80% - 80px);
}
.feature-icon1 {
	width: 150px;
	height: 150px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    
	background: linear-gradient(
		-155deg,
		rgb(30, 163, 141) 0%,
		rgb(30, 163, 141) 98%,
		rgb(243, 223, 224) 100%
	);
	border-radius: 50%;
	margin-left: 50px;
	
	}
/* MEDIDAS CELULAR */
@media only screen and  (max-width: 768px) {
    .feature-icon1{
        margin-left: 27%;   
    }
    .title_servis{
        font-size: 30px;
        font-weight: 900;
    }
}
/* MEDIDAS TABLET-IPAD */
@media only screen and  (min-width: 768px) {
    .feature-icon1{
           
        width: 110px;
	    height: 110px;
        text-align: center;
    }
    .icon-person{
        color: white;
        width: 90px;
	    height: 90px;
    }
    .icon-star{
        color: white;
        width: 90px;
	    height: 90px;
    }
    .icon-clock{
        color: white;
        width: 90px;
	    height: 90px;
    }
    #icon-person{
    background: linear-gradient(
		-155deg,
		rgb(30, 163, 141) 0%,
		rgb(30, 163, 141) 98%,
		rgb(243, 223, 224) 100%
	);
    border-radius: 100%;
  width: 100px; height: 100px;
}
.sub-title1 {
	font-size: 13px;
	color: #848484;
	font-weight: 400;
    line-height: 0.5;
	
}
.title1 {
	font-size: 15px;
	color: #272727;
	font-weight: 600;
    margin: 0 0 3px;
    
	
}

}
.btn-color1{
    background: #1FA38E;
}
.btn-color2{
    background: #1fa326;
}
/* MEDIDAS DE ESCRITORIO */
 @media only screen and (min-width: 1200px) {
    .feature-icon1{
        margin-left: 25%;   
    }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #1da08a !important ;
}
}    

</style>
