<template>
  <div id="app">
    <header-vue></header-vue>
    <!-- FIN HEADER -->
    <router-view/>
    <footer-vue></footer-vue>
  </div>
</template>
<script>
import FooterVue from './layouts/Footer.vue'
import HeaderVue from './layouts/Header.vue'

export default {
  components: {
    HeaderVue,
    FooterVue
  }

}
</script>
<style>
</style>
