<template>
  <div>
        <!-- Testimonial Area Start -->
    <div class="testimonial-area pb-40px">
        <div class="container">
            <div class="row col-md-12">
                <div class="col-md-2"> </div>
                <div class="col-md-8 text-center">
                    <h1 class="title_coment1"><b>Chilenanas es lo mejor cuando necesito una asesora de hogar</b> </h1>
                </div>
                <div class="col-md-2"></div>
                
            </div>
            <!-- Slider Start -->
            <div class="testimonial-wrapper swiper-container">
                <div class="swiper-wrapper">
                    <!-- Slider Single Item -->
                    <div class="swiper-slide">
                        <div class="testi-inner">
                            <div class="reating-wrap">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div class="testi-content">
                                <p>Muy contenta con el servicio, encontré a la colaboradora perfecta para mi hogar. 
                                    Es todo muy seguro y confiable, sin duda lo recomiendo
                                </p>
                            </div>
                            <div class="testi-author">
                                
                                <div class="author-name">
                                    <h4 class="name">Lorena Alfaro</h4>
                                    <span class="title">Las Condes</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Slider Single Item -->
                    <div class="swiper-slide">
                        <div class="testi-inner">
                            <div class="reating-wrap">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div class="testi-content">
                                <p>Exelente servicio, cumplen con todas las necesidades.
                                </p>
                            </div>
                            <div class="testi-author">
                               
                                <div class="author-name">
                                    <h4 class="name">María Ignacia Álvarez </h4>
                                    <span class="title">Vitacura</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Slider Single Item -->
                    <div class="swiper-slide">
                        <div class="testi-inner">
                            <div class="reating-wrap">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div class="testi-content">
                                <p>
                                    Lo recomiendo de todas maneras, buscan a la mejor colaboradora que cumpla con mis requerimientos. Muy feliz.
                                </p>
                            </div>
                            <div class="testi-author">
                                
                                <div class="author-name">
                                    <h4 class="name">Daniela Rojas</h4>
                                    <span class="title">Ñuñoa</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Slider Single Item -->
                    <div class="swiper-slide">
                        <div class="testi-inner">
                            <div class="reating-wrap">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div class="testi-content">
                                <p>
                                    Lejos el mejor portal para encontrar a la colaboradora perfecta para uno.
                                    Excelente servicio.   
                                </p>
                            </div>
                            <div class="testi-author">
                                
                                <div class="author-name">
                                    <h4 class="name">Andrea Guzmán</h4>
                                    <span class="title">Providencia</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Slider Single Item -->
                    
                    <!-- Slider Single Item -->
                </div>
            </div>
            <!-- Slider Start -->
        </div>
    </div>
    <!-- Testimonial Area End -->
  </div>
</template>

<script>
export default {
  name: 'testimonials-component',
  mounted () {
        /*---------------------
        Category Slider
     ---------------------- */

    var categorySlider = new Swiper('.testimonial-wrapper.swiper-container', {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 30,
        speed: 1500,
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            478: {
                slidesPerView: 1,
            },
            576: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: 3,
            },
        },
    });
  },
}
</script>

<style>
.title_coment1{
    font-size: 320%;
    font-weight:900
}
/* MEDIDAS CELULAR */
@media only screen and  (max-width: 768px) {
.title_coment1{
    font-size: 25px;
    font-weight:900
}
}

</style>
