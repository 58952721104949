<template>
    <div>
        <b-modal size="lg" id="modal-1" hide-footer  title="Perfil"  v-model="aperturaModalComp">        
                <b-card no-body class="overflow-hidden" style="width:540 px;">
                  <b-row no-gutters>
                    <b-col md="4">
                      <b-card-img  :src="url + data.idnana" alt="Image" class="rounded-0"></b-card-img><br>
                      <div style="text-align: center;" >
                        <h4 class="mt-5" ><b>Contactanos</b></h4>
                        <span style="font-size:20px ;">Llamanos</span><br>
                        <span style="font-size:20px ;">+569 9256 5857</span><br>
                       <span style="font-size:20px ;">Whatsapp</span><br> 
                        <b-button-group class="mt-1 ml-3" > 
                          <a  href="https://wa.me/56939291806" target="new"
                          >
                            <span style="font-size:20px ;">+569 3929 1806</span>
                          </a>
                      </b-button-group>
                      </div>
                      
                    </b-col>
                    <b-col md="8">
                      <b-card-body :title="data.primer_nombre
                       + ' '+ data.primer_apellido
                       +' '+ data.segundo_apellido">
                        <b-list-group class="mt-3">
                          <b-list-group-item class="d-flex justify-content-between align-items-center">
                            <b>{{ 'Edad' }}</b>
                            <b-badge variant="info" pill>{{data.edad}}</b-badge>
                          </b-list-group-item>
                          <b-list-group-item class="d-flex justify-content-between align-items-center">
                            <b>{{ 'Años de experiencia'}}</b>
                            <b-badge variant="info" pill>{{data.anios_experiencia}}</b-badge>
                          </b-list-group-item>
                          <b-list-group-item class="d-flex justify-content-between align-items-center">
                            <b>{{ 'Nacionalidad'}}</b>
                            <b-badge variant="info" pill>{{data.idnacionalidad}}</b-badge>
                          </b-list-group-item>
                          <b-list-group-item class="d-flex justify-content-between align-items-center">
                            <b>{{ 'Estado civil'}}</b>
                            <b-badge variant="info" pill>{{data.estado_civil}}</b-badge>
                          </b-list-group-item>
                          <b-list-group-item class="d-flex justify-content-between align-items-center">
                            <b>{{ 'Licencia de conducir'}}</b>
                            <b-badge variant="info" pill>{{data.licencia_conducir}}</b-badge>
                          </b-list-group-item>
                           <b-list-group-item class="d-flex justify-content-between align-items-center">
                            <b>{{ 'Hijos'}}</b>
                            <b-badge variant="info" pill>{{data.hijos}}</b-badge>
                          </b-list-group-item>
                          <b-list-group-item class="d-flex justify-content-between align-items-center">
                            <b>{{ 'Hora de inicio'}}</b>
                            <b-badge variant="info" pill>{{data.hora_inicio}}</b-badge>
                          </b-list-group-item>
                          <b-list-group-item class="d-flex justify-content-between align-items-center">
                            <b>{{ 'hora de termino'}}</b>
                            <b-badge variant="info" pill>{{data.hora_termino}}</b-badge>
                          </b-list-group-item>
                                                            
                        </b-list-group>
                          
                      </b-card-body>
                    </b-col>
                    
                  </b-row>
                  <hr>
                  <div v-if="id == 2">
                    <div v-if="data.dias.length > 0" style="display: flex; justify-content: center; align-items: center;" >
                      <div class="container-center" >
                        <div style="text-align: center;"><h5 ><b>Dias disponibles</b></h5></div>
                        <div style="text-align: center;" class="col">
                          <b-badge class="badge1" v-for="(item, index) in data.dias" :key="index"  pill>{{item}}</b-badge>
                        </div>
                      </div>
                    </div>      
                  </div>                 
                    <div style="display: flex; justify-content: center; align-items: center;" >
                      <div class="container-center" >
                        <div  style="text-align: center;"><h5 ><b>Servicios</b></h5></div>                       
                        <div style="text-align: center;" class="col">
                          <b-badge class="badge1" v-for="(item, index) in data.servicios" :key="index"  pill>{{item}}
                          </b-badge>
                        </div>
                      </div>    
                    </div>
                    <hr>
                    <div style="display: flex; justify-content: center; align-items: center;" >
                      <div class="container-center" >
                                               
                        
                          <div v-for="(item, index) in data.atributos" :key="index" >
                       <atributos :data="item.grupo" :id="index" :data2="item.atributos" />
                     
                          
                        </div>
                      </div>    
                    </div>
                    <!-- <div class="row ml-2">
                      <h4>Atributos</h4>
                      <div v-for="(item, index) in data.atributos" :key="index" class="col-md-3 ">
                       <atributos :data="item.grupo" :id="index" :data2="item.atributos" />
                      </div>
                    </div> -->
                  <hr>
                  <b-button style="background-color: #1fa326 ;" href="#" @click="portal()" >¡Contratar servicio!</b-button>
               
  
                </b-card>
        </b-modal>     
    </div>             
</template>

<script>
import Atributos from './Atributos.vue';



export default {
  components: { Atributos },
    props: ['abrirModal', 'data', 'id'],
    computed:{
        aperturaModalComp: {
            get()    {return this.abrirModal      },
            set(val) {this.$emit('cerrarModal'),val}
        }
    },
    components: {
      Atributos,
    },
    data() {
        return {
          url: "https://admincn.fabricadecodigo.dev:8081/imagen/nana/"

        } 
    },
    created () {           
      window.console.log('CONTENIDO PROPS NANA', this.id)
      this.formato()
    },
    methods: {
      portal(){
        window.location = "http://localhost:8082/login"
      },
      cerrarModal() {
        this.$emit('cerrarModal')
      },
      formato(){
        this.data.primer_nombre = this.data.primer_nombre.replace(/(^|\s)\S/g, l => l.toUpperCase()),
        this.data.primer_apellido = this.data.primer_apellido.replace(/(^|\s)\S/g, l => l.toUpperCase()),
        this.data.segundo_apellido = this.data.segundo_apellido.replace(/(^|\s)\S/g, l => l.toUpperCase()),
        this.data.idnacionalidad = this.data.idnacionalidad.replace(/(^|\s)\S/g, l => l.toUpperCase()),
        this.data.atributos.forEach(element => {
          element.grupo = element.grupo.replace(/(^|\s)\S/g, l => l.toUpperCase())
         
        });
      }
        
    }           
};
</script>
<style scoped>
.card.overflow-hidden {
  border: none !important;
}
.badge {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.95em;
    font-weight: 700;
    line-height: 1;
    color: rgb(3, 3, 3);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    background-color: #ffffff;
}
.badge1 {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.99em;
    font-weight: 700;
    line-height: 1;
    color: rgb(247, 236, 236);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    background-color: #00B290;
    margin-left: 10px;
    margin-bottom: 10px;
}
.rounded-0{
  width: 220px !important;
  height: 140px !important;
}
.btn_chat{
  margin-left: 10px;
  
}

</style>
