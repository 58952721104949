<template>
  <!-- Hero/Intro Slider Start -->
    <div class="row   "   >
      <div>
        <b-carousel
          id="carousel-fade"
      
          
          img-width="1024"
          img-height="480"
        >
        
        <iframe class="video" width="100%" height="600px" src="https://www.youtube.com/embed/PT71KH6JDk4"
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      
        <!-- <b-carousel-slide
          img-src="/fondo.png"
        >
        <div class="row caja-texto">
          <div >
            <h1 class="texto-carrusel">AGENCIA DE <br> NANAS WEB</h1>       
              <h5 class="sub-text-carrusel ">Somos CHILENANAS</h5>      
          </div>
        </div>
        <div class="row caja-texto2">
          <p class="parrafo-inicio">
            Ofrecemos un reguroso proceso de reclutamiento, basado en 5 certificaciones
            y al cumplir con estos requisitos pasa a ser una colaboradora de CHILENANAS.
          </p>
        </div>
        </b-carousel-slide> -->
      </b-carousel>
      <div class="container-fluid ">
      
    </div>  
    </div>    
      <!--<div class=" fondo-hijo">
        <h1>hola</h1>
      </div>-->
      
    </div>         
        <!-- Hero/Intro Slider End -->
</template>

<script>
export default {
  name: 'carrousel-component',
  mounted () {
        var heroSlider = new Swiper('.hero-slider.swiper-container', {
        loop: true,
        speed: 2000,
        effect: "fade",
        autoplay: {
            delay: 7000,
            disableOnInteraction: false,
        },
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });
  },
}
</script>

<style >
#foto_fondo1{
  max-width: 100%;
  display: block;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}
.fondo-hijo{
  background: yellow;
  position: absolute;
  align-items: center;
  justify-content: center;
}

/* MEDIDAS CELULAR */
@media only screen and  (max-width: 768px) {
 .texto-carrusel{
  color:#FFFDFD;
  font-size: 30px;
  font-weight: 900;
}
.sub-text-carrusel{
  color:rgb(237, 238, 238);
  font-size: 8px;
  margin-right: 70px;
}
.caja-texto{
margin-bottom: 5%;
}
.parrafo-inicio{
  font-size: 10px;
}
}
/* MEDIDAS TABLET-IPAD */
@media only screen and  (min-width: 768px) {
  .texto-carrusel{
  color: #FFFDFD;
  font-size: 40px;
  font-weight: 900;
}
.sub-text-carrusel{
  color: rgb(237, 238, 238);
  margin-right: 80px;
  margin-bottom: 5%;
  font-size: 15px;
}
.caja-texto{
margin-bottom: 3%;
}
.caja-texto2{
  margin-bottom: 15%;
}
.parrafo-inicio{
  margin-left: 10px;
}
}
/* MEDIDAS DE ESCRITORIO */
 @media only screen and (min-width: 1200px) {
  .texto-carrusel{
  color: #FFFDFD;
  font-size: 60px;
  font-weight: 900;
}
.sub-text-carrusel{
  color: rgb(237, 238, 238);
  margin-right: 130px;
  margin-bottom: 5%;
  margin-bottom: .0rem;
}
.caja-texto{
margin-bottom: 5%;
}
.caja-texto2{
  margin-bottom: 20%;
  max-width: 70%;
 
}
.parrafo-inicio{
  margin-left: 25%;
  font-size: 20px;
  font-weight: 100;

}
}




</style>
