<template>
  <div class=" col-md-12 col-sm-5 "  >
    <div id="baner" class="container-fuid col-md-12  col-sm-5 d-none d-sm-block">
      <img src="@/assets/images/logo/imagen-09.png" id="foto-fondo" alt=""> 
      <div  class=" yap container  text-center">
        <h4 class="title2 ">Conoce nuentros pilares con los que seleccionamos
           a las mejores asesoras puertas adentro, puerta afuera y por día. 
        </h4>
      </div>
      
      
    </div>
    <div>  
      <!--  -->
      <div  class=" col-md-12 container d-none d-sm-block iner2_color">
        <div class="row col-md-12 col-sm-5">
          <div class="col-md-3"></div>
          <div class="col-md-6  text-center">
            <h2 class="titulo3 mt-5 ">¿Por qué elegirnos?</h2>
          </div>
          <div class="col-md-3"></div>
        </div>
        <div class="row col-md-12 col-sm-5">
          <div class="col-md-3"></div>
          <div class="col-md-6 text-center">
            <p class="parrafo">Porque ofrecemos un riguroso proceso de reclutamiento, basado en 5
              certificaciones y al cumplir una colaboradora con estos requisitos pasa a ser una Chilenanas recomendada.
            </p>
          </div>
          <div class="col-md-3"></div>
        </div>
          <div class="row text-center mt-4 ">
            <div class="col-md-2  caja1 d-none d-sm-block ">
                <div class="  container-center ml-1 mt-3" >
                 <img src="@/assets/images/icons/a.png" id="icon-a" alt="">
                </div>   
              <div class="container-center ml-1 mt-1">
                <p class="parrafo2">Certificación Presencial:
                  Entrevistas personales en nuestras oficinas. 
                </p>
              </div>
            </div>
            <div class="col-md-2 col-ms-1 caja2 d-none d-sm-block">
              <div class=" container-center  mt-3" >
                <img src="@/assets/images/icons/b.png" id="icon-b" alt="">
              </div>   
              <div class="container-center ml-1 mt-1">
                <p class="parrafo2">Certificación de Recomendaciones, de sus anteriores empleadores. 
                </p>
              </div> 
            </div>
            <div class="col-md-2  caja3 d-none d-sm-block">
              <div class=" container-center mt-3 d-none d-sm-block" >
                <img src="@/assets/images/icons/c.png" id="icon-c" alt="">
              </div>   
              <div class="container-center mt-1">
                <p class="parrafo2">Certificación de Antecedentes Legales.</p>
              </div>
            </div>
            <div class="col-md-2  caja4 d-none d-sm-block">
              <div class=" container-center mt-3" >
                <img src="@/assets/images/icons/d.png" id="icon-d" alt="">
              </div>   
              <div class="container-center mt-1">
                <p class="parrafo2">Certificación Psicológica (Test básico y Premium).</p>
              </div>
            </div>
            <div class="col-md-2  caja5 d-none d-sm-block">
              <div class=" container-center mt-3" >
                <img src="@/assets/images/icons/e.png" id="icon-e" alt="">
              </div>   
              <div class="container-center ml-1 mt-1">
                <p class="parrafo2">Certificación de Seguridad Básica y Premium (Cómo evitar estafas telefónicas).</p>
              </div>
            </div>
          </div>
      </div>
     <div class=" caca d-md-none">
        <div class="feature-area pb-100px">
        <div class="container">
           <div class="row">
                <div class="col-12">
                    <div class="section-title text-center mb-0">
                        <h2 class="titulo3"><b>¿Por qué elegirnos?</b> </h2>
                        <p class="parrafo">Porque ofrecemos un riguroso proceso de reclutamiento, basado en 5
                          certificaciones y al cumplir una colaboradora con estos requisitos pasa a ser una Chilenanas recomendada.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row col-md-12 mt-5">
                <div class=" col-md-3  ">
                    <!-- single item -->
                    <div class="single-feature1">            
                        <div class="feature-content1 text-center">
                                <div class="  container ml-5 mt-3" >
                                <img src="@/assets/images/icons/a.png" id="icon-a" alt="">
                               </div>
                            <h4 class="title_c mt-3" >Certificación Presencial:
                              Entrevistas personales en nuestras oficinas.
                            </h4>
                        </div>
                    </div>
                </div>
                <!-- single item -->
                <div class="col-md-3 mb-md-30px mb-lm-30px mt-lm-30px mt-4">
                    <div class="single-feature2">
                        <div class="feature-content1 text-center">
                                <div class="  container ml-5 mt-3" >
                                <img src="@/assets/images/icons/b.png" id="icon-b" alt="">
                               </div>
                            <h4 class="title_c mt-3" >
                              Certificación de Recomendaciones, de sus anteriores empleadores. 
                            </h4>
                        </div>
                    </div>
                </div>
                <!-- single item -->
                <div class=" col-md-3 mb-md-30px mb-lm-30px mt-lm-30px ">
                    <div class="single-feature3">
                        <div class="feature-content1 text-center">
                                <div class="  container ml-5 mt-3" >
                                <img src="@/assets/images/icons/c.png" id="icon-c" alt="">
                               </div>
                            <h4 class="title_c mt-3" >
                              Certificación de Antecedentes Legales.
                            </h4>
                        </div>
                    </div>
                </div>
                <div class=" col-md-3 mb-md-30px mb-lm-30px mt-lm-30px">
                    <div class="single-feature4">
                        <div class="feature-content1 text-center">
                                <div class="  container ml-5 mt-3" >
                                <img src="@/assets/images/icons/d.png" id="icon-d" alt="">
                               </div>
                            <h4 class="title_c mt-3" >
                              Certificación Psicológica (Test básico y Premium).
                            </h4>
                        </div>
                    </div>
                </div>
                 <!-- single item -->
                <div class="col-md-3 mb-md-30px mb-lm-30px mt-lm-30px">
                    <div class="single-feature5">
                        <div class="feature-content1 text-center">
                                <div class="  container ml-5 mt-3" >
                                <img src="@/assets/images/icons/e.png" id="icon-e" alt="">
                               </div>
                            <h4 class="title_c mt-3" >
                              Certificación de Seguridad Básica y Premium (Cómo evitar estafas telefónicas).
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

    </div>
     
    <div class="col-md-12 col-sm-12 ancho d-none d-sm-block" ></div>
    <div class="col-md-12 col-sm-12 ancho d-none d-sm-block" ></div>
    <div class="row col-md-12 col-sm-5 ">
      <div class="col-md-4 mt-5 d-none d-sm-block "></div>
      <div class="col-md-4  text-center">
        <h2 class="titulo3 mt-5 d-none d-sm-block">¿Quiénes somos?</h2>
        <h2 class="titulo3 d-md-none ">¿Quiénes somos?</h2>
      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="row col-md-12 col-sm-5">
      <div class="col-md-3"></div>
      <div class="col-md-6 text-center">
        <p class="parrafo">Somos una Agencia con 8 años de el mercado especializada en la selección
           y reclutamiento de colaboradoras, donde nuestros clientes encontrarán a las mejores colaboradoras para el hogar.
        </p>
      </div>
      <div class="col-md-3"></div>
    </div>
    <div class="row text-center mt-5 col-md-12 col-sm-5" id="comofuncionamos">
      <div class="col-md-4 cajaa ">
        <div class="container-center ml-5 hola ">
          <div class="row col-md-12">
            <div class="col-md-4 col-sm-5 ">
              <img src="@/assets/images/icons/1a.png" id="golo3"  alt="">
            </div> 
            <div class="col-md-8">   
              <h1 class="titulo4"><b>¿Cómo</b> funcionamos?</h1>
              <p class="subtitulo4">Pódras pagar vía webpay a través de nuestro portal o vía transferencia contactanos directamente.</p>
            </div>
          </div>
          
        </div>
      </div>
      <div class="col-md-4 cajab ">
        <div class="container-center ml-5 hola ">
          <div class="row col-md-12">
            <div class="col-md-4 ">
              <img src="@/assets/images/icons/1b.png" id="golo3"  alt="">
            </div>
            <div class="col-md-8">  
              <h1 class="titulo4"><b>¿Cómo</b> funcionamos?</h1>
              <p class="subtitulo4">Ofrecemos garantías de reemplazo de colaboradoras, en períodos correspondientes al tipo de servicio
                (30, 45 o 60 días), con cambios hasta cinco colaboradoras en el tiempo correspondiente.
              </p>
            </div>
          </div>
          
        </div>
      </div>
      <div class="col-md-4 cajac ">
        <div class="container-center ml-5 hola ">
          <div class="row col-md-12">
            <div class="col-md-4 ">
              <img src="@/assets/images/icons/1c.png" id="golo3"  alt="">
            </div>
            <div class="col-md-8">  
              <h1 class="titulo4"><b>¿Cómo</b> funcionamos?</h1>
              <p class="subtitulo4">Conoce el paso a paso de la forma rápida y fácil de acceder a tu futura colaboradora de Chilenanas. </p>
              <a  href="/videos" > <b-button id="btn-conocenos" class="ml-2" variant="dark" >Conócenos más</b-button></a>
            </div>
          </div>
          
        </div>
      </div>
    </div>  
  </div>
</template>

<script>
export default {
  name: 'informacion-component'
}
</script>

<style>
#btn-conocenos{
  float: left;
  
}
.yap{
  position: relative;
  top: 80px;
  max-width: 100%;
  display: block;
  width: 100%;

}
#foto-fondo{
  max-width: 100%;
  display: block;
  width: 100%;
 
  position: absolute;
}
#icon-a{
  background-color: #1e6de0;
  border-radius: 100%;
  width: 90px; height: 90px;
  color: whitesmoke;
}
#icon-b{
  background-color: #00ccaa;
  border-radius: 100%;
  width: 90px; height: 90px;
  color:whitesmoke;
}
#icon-c{
  background-color: #c14242;
  border-radius: 100%;
  width: 90px; height: 90px;
  color: whitesmoke;
}
#icon-d{
  background-color: #a24dce;
  border-radius: 100%;
  width: 90px; height: 90px;
  color: white;
}
#icon-e{
  background-color: #ffb031;
  border-radius: 100%;
  width: 90px; height: 90px;
  color: white;
}
#golo3{
    width: 180px; height:180px;
}
.hola{
  width: 3xp;
  margin-top: 15ex;
}
.hola2{
  background-color:rgb(58, 49, 50);
  width: 3xp;
}
.titulo4{
  font-size: 45px;
  margin-left: 15px;
	color:white;
	text-align: justify;
  margin: 2 2 3px;
}
.subtitulo4{
  color:white;
  margin-left: 15px;
	text-align: left;
}
.title2 {
	font-size: 45px;
	color:rgb(247, 245, 245);
	font-weight: 700;
  margin: 5 5 3px;
  text-align: center;   
}

.iner2_color{
  background-color: white;
  height:400px;
  position: relative;
  top: 180px;
  padding: auto;
}
.titulo3{
  font-size: 55px;
	color:rgb(32, 32, 32);
	font-weight: 800;
  margin: 0 0 3px;
  text-align: center;
}
.parrafo{
  font-size: 22px;
}
.parrafo2{
  font-size: 16px;
  color: aliceblue;
}
.caja1{
  height:195px;
  background-color:#135cd0;
  margin-left: 60px;
}
.caja2{
  height:195px;
  background-color:#00b290;
  margin-left: 30px; 
}
.caja3{
  height:195px;
  background-color:#b22222;
  margin-left: 30px;
}
.caja4{
  height:195px;
  background-color:#9735c4;
  margin-left: 30px;
}
.caja5{
  height:190px;
  background-color:#ffa200;
  margin-left: 30px;
}
.rounded-circle{
  width: 80px;
  height: 80px;
  color: rgb(156, 170, 182);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ancho{
  height: 100px;
}
.cajaa{
  background-color:#00b290 ;
  height: 450px;
}
.cajab{
  background-color:#1e6de0;
  height: 450px;
}
.cajac{
  background-color:#00b290 ;
  height: 450px;
}
/* MEDIDAS PARA CELULAR */
@media only screen and (max-width: 768px) {
  .iner2_color{
  top: 1px;
}
.hola{
  width: 3xp;
  margin-top: 5px;
}
.titulo3{
  font-size: 55px;
	color:rgb(32, 32, 32);
	font-weight: 800;

  text-align: center;
}
.single-feature1{
  background-color: #135cd0;
  padding: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e8e8e8;  
}
.single-feature2{
  background-color: #00b290;
  padding: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e8e8e8;  
}
.single-feature3{
  background-color: #b22222;
  padding: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e8e8e8;  
}
.single-feature4{
  background-color: #9735c4;
  padding: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e8e8e8;  
}
.single-feature5{
  background-color: #ffa200;
  padding: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e8e8e8;  
}
#icon-a{
  background-color: #1e6de0;
  border-radius: 100%;
  width: 180px; height: 180px;
  color: whitesmoke;
}
#icon-b{
  background-color: #00ccaa;
  border-radius: 100%;
  width: 180px; height: 180px;
  color:whitesmoke;
}
#icon-c{
  background-color: #c14242;
  border-radius: 100%;
  width: 180px; height: 180px;
  color: whitesmoke;
}
#icon-d{
  background-color: #a24dce;
  border-radius: 100%;
  width: 180px; height: 180px;
  color: white;
}
#icon-e{
  background-color: #ffb031;
  border-radius: 100%;
  width: 180px; height: 180px;
  color: white;
}
.sub-title1{
  font-size: 18px;
}
.parrafo{
  font-size: 20px;
}
.title_c{
  color: #e8e8e8;
}
}


</style>
