<template>
  <div class="home">
    <carrousel-vue></carrousel-vue>

    <features-vue class="mt-5"></features-vue>
    <informacion-vue class="mt-3"></informacion-vue>
    <testimonials-vue class="mt-5"></testimonials-vue>
   
  </div>
</template>

<script>

import CarrouselVue from '../components/Carrousel.vue'
import FeaturesVue from '../components/Features.vue'

import TestimonialsVue from '../components/Testimonials.vue'
import InformacionVue from '../components/Informacion.vue'

// @ is an alias to /src

export default {
  name: 'Home',
  components: {
    
    CarrouselVue,
    InformacionVue,
    FeaturesVue,
    TestimonialsVue
  }
}
</script>
